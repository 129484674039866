.airport-bg-img {
  height: 60vh;
  width: 100vw;
  background: url(../images/plane.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.airport-info-text-on-img {
  position: absolute;
  top: 40px;
  left: 30px;
  font-size: 2rem;
  color: #fff;
  width: 45%;
  font-weight: 600;
}

.airport-bttom-plane-img {
  width: 80%;
  max-width: 780px;
  border-radius: 16px;
  margin: auto;
}

.airport-contact-us {
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 20px;
  margin-top: 3rem;
  max-width: 880px;
  margin: 1rem auto 2rem auto;
}

@media only screen and (max-width: 768px) {
  .airport-info {
    text-align: justify;
  }
  .airport-info-text-on-img {
    left: 12px;
    top: 17px;
    width: 95%;
    font-size: 1.3rem;
    text-align: center;
  }
  .airport-contact-us {
    flex-direction: column;
    align-items: center;
  }
}
