/* Footer css */
.footer-container {
  background-color: #06a77d;
  text-align: center;
  padding-bottom: 15px;
}
.footer {
  height: 70px;
  width: 100%;
  margin-top: 17%;
  background-color: #06a77d;
}
.social-media {
  font-size: 0.7rem;
  width: 60%;
  height: 40px;
  border-right: 2px solid white;
  padding-left: 2%;
}
.social-media i {
  font-size: 0.9rem;
}
.copyright {
  font-size: 0.7rem;
  margin: 0;
}
.social-m-link:hover {
  color: #212529 !important;
}
.home-logo-border {
  width: 150px;
}
/* footer responsive */
@media only screen and (max-width: 768px) {
  .footer {
    height: 200px;
    justify-content: center !important;
    flex-direction: column;
    align-items: center !important;
  }
  .social-media {
    flex-direction: column;
    margin-bottom: 20px;
    height: 120px;
    border-right: none;
    border-bottom: 2px solid white;
  }
}
