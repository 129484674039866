.call-us-container {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  width: 370px;
  justify-content: space-around;
}
.call-us-img {
  width: 200px;
  height: 167px;
  filter: drop-shadow(2px 4px 8px hsla(0deg, 0%, 0%, 0.5));
}
.call-us-number-button {
  flex-direction: column;
  padding: 20px 6px;
}
.call-us-button {
  width: 210px;
    border: 2px solid #06a77d;
    border-radius: 7px;
    background-color: #fffae5;
    color: #06a77d;
}
.call-us-button:hover {
  border: 2px solid #06a77d;
  background-color: #06a77d;
  color: #fff;
}
.text-96 {
  color: rgb(227 13 13);
  font-size: 2.6rem;
  font-weight: 700;
  padding: 3px;
}
.text-us {
  color: rgb(227 13 13);
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .call-us-container {
    margin-bottom: 24px;
    padding-bottom: 20px;
  }
  .call-us-button {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 560px) {
  .call-us-container {
    width: 330px;
  }
}
