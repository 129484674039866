.bg-card-img {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  /* background: url(../images/airport.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}
.rose-taxi-norwich {
  position: absolute;
  top: 0px;
  right: 0;
  width: 173px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #09a77d;
  color: white;
  z-index: 22;
  border-radius: 0px 8px;
  font-weight: 700;

}
.services-container .card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
}
.card-text {
  min-height: 70px;
  text-align: justify;
}
.services-contact-us {
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 20px;
  margin-top: 3rem;
  max-width: 880px;
  margin: 3rem auto 2rem auto;
}

@media only screen and (max-width: 768px) {
  .services-contact-us {
    flex-direction: column;
    align-items: center;
  }
}
