.contact-us {
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 20px;
  margin-top: 3rem;
  max-width: 880px;
  margin: 3rem auto 2rem auto;
}
.all-contact-info {
  background-color: #06a77d;
  color: #fff;
  width: 85%;
  max-width: 350px;
  margin: auto;
  padding: 8px;
  border-radius: 12px;
}
.contact-item {
  margin: 10px 0;
}
@media only screen and (max-width: 768px) {
  .contact-us {
    flex-direction: column;
    align-items: center;
  }
}
