.contact-container {
  background-color: #fffae5;
  width: 370px;
  color: #434b49;
  border-radius: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: center;
  position: relative;
}
form {
  width: 100%;
  background-color: #fffae5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 12px 0;
}
.form-control {
  border: 1px solid #dbdfe3;
  border-radius: 0;
  padding-left: 1.3rem;
  color: #06a77d;
  border-radius: 6px;
}
.form-control::placeholder {
  color: rgba(220, 198, 198, 0.857);
  opacity: 1; /* Firefox */
  font-size: 0.8rem;
}

.form-control::-ms-input-placeholder {
  color: rgba(189, 145, 145, 0.847);
  font-size: 0.8rem;
}
.form-control:focus {
  border-radius: 8px;
}
textarea.form-control {
  border: 1px solid #dbdfe3;
  border-radius: 5px;
}
.input-area {
  position: relative;
  width: 83%;
}
.form-icon {
  position: absolute;
  top: 36px;
  left: 5px;
  color: #bd91918f
}

.phone-label {
  font-size: 0.55rem;
}

#send-button {
  background-color: #fffae5;
  border: 2px solid #06a77d;
  color: #06a77d;
  font-weight: 700;
}
#send-button:hover {
  background-color: #06a77d;
  color: #fff;
}
.contact-me-header {
  align-self: center;
  padding-left: 24px;
}


.info-icons {
  transform: scale(1);
  transition: color 0.7s, transform 0.7s;
}
.info-icons:hover {
  color: #004046;
  font-weight: 700;
  transform: scale(1.1);
}

@media only screen and (max-width: 770px) {
  .contact-container {
    padding: 0;
  }
}
@media only screen and (max-width: 560px) {
  .contact-container {
    width: 330px;
  }
  .contact-me-header {
    padding-left: 0;
  }

  .input-area {
    width: 75%;
  }
}
