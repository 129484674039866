.navbar-container {
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.navbar-container .container-fluid {
  background-color: #fffae5 !important;
}
div.navbar-collapse {
  justify-content: end;
}
.navbar-nav {
  display: flex;
  align-items: center;
}
.nav-item {
  margin-left: 6px;
}
.nav-item a {
  color: #06a77d;
  text-decoration: none;
  padding: 6px;
  border-bottom: 3px solid #fffae5;
  border-radius: 3px;
  font-weight: 500;
  transition: color 0.4s ease-in-out, border 0.4s ease-in-out;
}
.nav-item a:hover {
  color: #ce2807;
  border-bottom: 3px solid #ce2807;
}
.active {
  border-bottom: 3px solid #ce2807 !important;
  color: #ce2807 !important;
  border-radius: 3px;
}

@media only screen and (max-width: 768px) {
  .logo {
    width: 120px;
  }
  .nav-item {
    margin-bottom: 12px;
  }
}
