.banner {
  height: 60px;
  width: 100%;
  background-color: #06a77d;
  color: #fff;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.banner p {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.banner .text-danger  {
  font-size: 2.1rem;
}
.banner-call-us-button {
  width: 160px;
  border: 2px solid #06a77d;
  border-radius: 7px;
  background-color: #fffae5;
  color: #06a77d;
  padding: 5px;
}
.banner-text-us {
  color: rgb(227 13 13);
  font-weight: 700;
}
.banner-call-us-button:hover {
  background-color: #e5171fd9;
  color: #fff;
}
.banner-call-us-button:hover .banner-text-us {
  color: #06a77d;
}
@media only screen and (max-width: 560px) {
  .banner {
    padding: 16px 12px;
  }
  .banner p {
    font-size: 1.2rem;
  }
  .banner .text-danger {
    font-size: 1.4rem;
  }
}
