.home-container {
  position: relative;
  width: 100vw;
  /* margin-top: 48px; */
}

.bg-img {
  position: relative;
  width: 100%;
  height: 80vh;
  background: url(../images/horizontal1.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.rose-taxi-norwich-home {
  width: 100vw;
  height: 50px;
  line-height: 40px;
  font-size: 4rem;
  font-weight: 600;
  color: #e5171f;
  position: absolute;
  bottom: 0px; 
  right: 0px;
  padding: 0 10px 10px 0;
  text-align: end;
  
}
.rose-taxi-norwich-home  .rose-text {
  color: #09a77d;
}
.mycar-img {
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 45%;
}
.home-logo-border {
  width: 100px;
}
.home-img-texts {
  width: 35%;
  color: #434b49;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}
.home-img-texts p {
  font-weight: 500;
}
.home-plus-info {
  width: clamp(300px, 60%, 500px);
  text-align: justify;
  margin: auto;
}
.home-contact-us {
  justify-content: space-between;
  align-items: stretch;
  padding: 10px 20px;
  margin-top: 3rem;
  max-width: 880px;
  margin: 3rem auto 2rem auto;
}
/****** List Group *********/
.list-group-item {
  border: 0;
  border-bottom: 2px solid #e9ecef;
  background-color: #fffae5;
}
.serveces-group ul {
  width: 280px;
}
.list-group-item {
  color: #434b49;
}
.list-group-item:nth-child(odd) {
  color: #e5171f;
}
hr:not([size]) {
  height: 5px;
  border-radius: 5px;
}
.click-here-for-serveces {
  font-size: 1.3rem;
  color: #e5171f;
}
@media only screen and (max-width: 768px) {
  .home-container {
    /* margin-top: 44px; */
  }
  .home-contact-us {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .bg-img {
    background: url(../images/vertical1.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .rose-taxi-norwich-home {
    font-size: 3rem;
  }
  .home-img-texts {
    width: 100%;
    margin-left: auto !important;
    margin: auto;
  }
  .home-logo-border {
    margin-top: 24px !important;
  }

  .mycars-container {
    flex-direction: column;
    padding: 0 !important;
    margin: auto;
    justify-content: center !important;
  }
  .mycar-img{
    width: 90%;
    margin-bottom: 20px;
  }
  .serveces-group {
    flex-direction: column;
  }
  .serveces-group ul {
    width: 320px;
    padding-left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .rose-taxi-norwich-home {
    font-size: 2rem;
  }
}